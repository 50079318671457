import articleImage01 from "../../images/mag/article01/01.jpg";
import articleImage02 from "../../images/mag/article02/01.jpg";
import articleImage03 from "../../images/mag/article03/01.jpg";
import articleImage04 from "../../images/mag/article04/01.jpg";
import articleImage05 from "../../images/mag/article05/01.jpg";
import articleImage06 from "../../images/mag/article06/01.jpg";
import articleImage07 from "../../images/mag/article07/01.jpg";
import articleImage08 from "../../images/mag/article08/01.jpg";
import articleImage09 from "../../images/mag/article09/01.jpg";
import articleImage10 from "../../images/mag/article10/01.jpg";
import articleImage11 from "../../images/mag/article11/01.jpg";
import articleImage12 from "../../images/mag/article12/01.jpg";
import articleImage13 from "../../images/mag/article13/01.jpg";
import articleImage14 from "../../images/mag/article14/01.jpg";
import articleImage15 from "../../images/mag/article15/01.jpg";
import articleImage16 from "../../images/mag/article16/01.jpg";
import articleImage17 from "../../images/mag/article17/01.jpg";
import articleImage18 from "../../images/mag/article18/01.jpg";
import articleImage19 from "../../images/mag/article19/01.jpg";
import articleImage20 from "../../images/mag/article20/01.jpg";
import articleImage21 from "../../images/mag/article21/01.jpg";
import articleImage22 from "../../images/mag/article22/01.jpg";
import articleImage23 from "../../images/mag/article23/01.jpg";
import articleImage24 from "../../images/mag/article24/01.jpg";
import articleImage25 from "../../images/mag/article25/01.jpg";
import articleImage26 from "../../images/mag/article26/01.jpg";
import articleImage27 from "../../images/mag/article27/01.jpg";
import articleImage28 from "../../images/mag/article28/01.jpg";
import articleImage29 from "../../images/mag/article29/01.jpg";
import articleImage30 from "../../images/mag/article30/01.jpg";
import articleImage31 from "../../images/mag/article31/01.jpg";
import articleImage32 from "../../images/mag/article32/01.jpg";
import articleImage33 from "../../images/mag/article33/01.jpg";
import articleImage34 from "../../images/mag/article34/01.jpg";
import articleImage35 from "../../images/mag/article35/01.jpg";
import articleImage36 from "../../images/mag/article36/01.jpg";
import articleImage37 from "../../images/mag/article37/01.jpg";
import articleImage38 from "../../images/mag/article38/01.jpg";
import articleImage39 from "../../images/mag/article39/01.jpg";
import articleImage40 from "../../images/mag/article40/01.jpg";
import articleImage41 from "../../images/mag/article41/01.jpg";
import articleImage42 from "../../images/mag/article42/01.jpg";
import articleImage43 from "../../images/mag/article43/01.jpg";
import articleImage44 from "../../images/mag/article44/01.jpg";
import articleImage45 from "../../images/mag/article45/01.jpg";
import articleImage46 from "../../images/mag/article46/01.jpg";
import articleImage47 from "../../images/mag/article47/01.jpg";
import articleImage48 from "../../images/mag/article48/01.jpg";
import articleImage49 from "../../images/mag/article49/01.jpg";
import articleImage50 from "../../images/mag/article50/01.jpg";
import articleImage51 from "../../images/mag/article51/01.jpg";
import articleImage52 from "../../images/mag/article52/01.jpg";
import articleImage53 from "../../images/mag/article53/01.jpg";
import articleImage54 from "../../images/mag/article54/01.jpg";
import articleImage55 from "../../images/mag/article55/01.jpg";
import articleImage56 from "../../images/mag/article56/01.jpg";
import articleImage57 from "../../images/mag/article57/01.jpg";
import articleImage58 from "../../images/mag/article58/01.jpg";
import articleImage59 from "../../images/mag/article59/01.jpg";
import articleImage60 from "../../images/mag/article60/01.jpg";
import articleImage61 from "../../images/mag/article61/01.jpg";
import articleImage62 from "../../images/mag/article62/01.jpg";
import articleImage63 from "../../images/mag/article63/01.jpg";
import articleImage64 from "../../images/mag/article64/01.jpg";
import articleImage65 from "../../images/mag/article65/01.jpg";
import articleImage66 from "../../images/mag/article66/01.jpg";
import articleImage67 from "../../images/mag/article67/01.jpg";
import articleImage68 from "../../images/mag/article68/01.jpg";
import articleImage69 from "../../images/mag/article69/01.jpg";
import articleImage70 from "../../images/mag/article70/01.jpg";
import articleImage71 from "../../images/mag/article71/photo11.jpg";


export const article = [
  {
    title: 'معرفی کامل نمایشگاه بین المللی معماری و دکوراسیون میدکس در تهران به همراه عکس و راهنما | بهترین سایت معرفی رویداد های ملکی | بزرگترین فایلینگ املاک و مستغلات هومینجا',
    h1: "نمایشگاه میدکس چیست؟",
    desc: "نمایشگاه میدکس از معتبرترین نمایشگاه های معماری و دکوراسیون داخلی می باشد. در هومینجا به تعریف و بررسی اهداف برگزاری نمایشگاه می پردازیم",
    author: "نیلوفر پورقلی",
    date: "2021/01/23",
    image: articleImage01,
    link: "/Midex",
    tags: ["دانستنی ها"],
    access: []
  },
  {
    title: 'معرفی کامل بهترین و دنج ترین کافی شاپ و کافه های شمال شهر تهران به همراه عکس، ادرس و تلفن | بهترین کافه های تهران | هومینجا بهترین سایت خرید و فروش آپارتمان ',
    h1: "معرفی جذاب ترین کافه های شمال تهران همراه آدرس و شماره تلفن",
    desc: "کافه مکانی است که می توانید با دوستان و آشنایان خود برای یک قرار دوستانه یا قرار کاری ملاقات داشته باشید ",
    author: "نیلوفر پورقلی",
    date: "2021/01/24",
    image: articleImage02,
    link: "/north-of-Tehran-cafe",
    tags: ["دانستنی ها"],
    access: []
  },
  {
    title: 'معرفی بهترین برند های فروش کالای خواب به همراه ادرس و تلفن | خرید کالای خواب ایرانی و خارجی | هومینجا بهترین سایت راهنمای خانه و سبک زندگی ',
    h1: "کالای خواب از کجا بخریم؟",
    desc: "برای داشتن یک خواب راحت انتخاب کالای خواب مناسب بسیار حائز اهمیت است. در این مقاله از هومینجا به معرفی کالای خواب مرغوب می پردازیم.",
    author: "نیلوفر پورقلی",
    date: "2022/07/11",
    image: articleImage03,
    link: "/where-to-buy-sleep-product",
    tags: ["دانستنی ها"],
    access: []
  },
  {
    title: 'معرفی و بررسی کامل اسناد انواع ملک و مستغلات به همراه توضیحات و عکس | راهنمای کامل سند ملک | بهترین سایت افزایش اطلاعات ملکی هومینجا',
    h1: "معرفی اسناد ملکی و بررسی انواع سند املاک و مستغلات",
    desc: "یکی از مهمترین معاملات خرید و فروش ملک است. بنابراین بهتر است با انواع سند های ملکی آشنا شوید تا هنگام معامله دچار مشکل نشوید",
    author: "نیلوفر پورقلی",
    date: "2021/01/23",
    image: articleImage04,
    link: "/property-document",
    tags: ["مسائل حقوقی"],
    access: []
  },
  {
    title: 'معرفی و بررسی بهترین کافی شاپ با قلیان به همراه عکس، ادرس و تلفن | راهنمای قلیان گردی در تهران | هومینجا بهترین سایت معرفی کافه و رستوران در تهران ',
    h1: "کافه های قلیان دار در تهران",
    desc: "امروزه کمتر کسی برای چای و قلیان سفره خانه های قدیمی را انتخاب می کند . افراد ترجیح می دهند بیشتر به کافه های قلیان دار بروند",
    author: "نیلوفر پورقلی",
    date: "2021/01/23",
    image: articleImage05,
    link: "/Tehran-cafe-with-hooka",
    tags: ["دانستنی ها"],
    access: []
  },
  {
    title: 'معرفی و بررسی کامل انواع سایبان پیش ساخته به همراه عکس و کارایی | خرید سایبان پیش ساخته در تهران | هومینجا بهترین سایت جستجوی ملک در تهران ',
    h1: "سایبان پیش ساخت چیست؟",
    desc: "سایبان پیش ساخت نسل جدیدی از سایبان ها می باشند. این نوع از سایبان ها در قسمت خارجی ساختمان وصل می شوند.",
    author: "نیلوفر پورقلی",
    date: "2022/08/26",
    image: articleImage06,
    link: "/premade-canopy",
    tags: ["تکنولوژی و فناوری"],
    access: []
  },
  {
    title: 'بررسی جامع شرایط و نحوه دریافت وام مسکن 1401 از بانک های ایران | معرفی انواع وام مسکن در سال 1401 به همراه مدارک و شرایط | بهترین سایت اطلاع رسانی املاک و مستغلات هومینجا',
    h1: "نحوه دریافت وام مسکن ۱۴۰۱",
    desc: " این روزها با توجه به بالا رفتن قیمت ملک خرید مسکن بسیار دشوار شده است. بطوریکه خیلی از افراد بدون دریافت وام مسکن قادر به خرید ملک نیستند.",
    author: "نیلوفر پورقلی",
    date: "2021/01/24",
    image: articleImage07,
    link: "/way-to-get-house-loan",
    tags: ["بازار مسکن"],
    access: []
  },
  {
    title: 'فنگ شویی به زبان ساده | اصول چیدمان وطراحی خانه بر اساس انرژی به سبک چینی | همه ی آنچه در مورد فنگ شویی باید بدانید | بهترین مقالات طراحی و دکوراسیون منزل | هومینجا سامانه آنلاین معاملات املاک',
    h1: "فنگ شویی چیست؟ اصول چیدمان دکوراسیون منزل بر اساس رسوم چینی",
    desc: " فنگ شویی هنر و علمی قدیمی است که برای چیدمان و دکوراسیون درست اشیا در ساختمان های مسکونی و تجاری به کار می رود",
    author: "نیلوفر پورقلی",
    date: "2022/07/01",
    image: articleImage08,
    link: "/fengshui",
    tags: ["خانه و سبک زندگی"],
    access: []
  },
  {
    title: 'همه چیز در مورد روشنایی منزل و نقش لوستر در دکوراسیون داخلی خانه | بررسی کامل انواع لوستر و تاثیر آن بر زیبایی آپارتمان | بهترین مقالات دکوراسیون منزل | هومینجا راهنمای انتخاب مسکن',
    h1: "انتخاب لوستر مناسب در دکوراسیون داخلی منزل",
    desc: "لوستر بعنوان یکی از تجهیزات نورپردازی ابهت و جذابیتی بینظیر به دکوراسیون داخلی خانه می دهد. لوسترها در سبک های مختلف طراحی می شوند",
    author: "نیلوفر پورقلی",
    date: "2021/01/24",
    image: articleImage09,
    link: "/chandelier-in-decoration",
    tags: ["خانه و سبک زندگی"],
    access: []
  },
  {
    title: 'دیدنی ترین مکان های تهران | معرفی و بررسی 9 مکان جذاب در تهران برای گردش و تفریح |جاذبه های تهران به همراه ادرس و تفشه | بهترین سایت معرفی مکان های دیدنی در تهران | هومینجا سامانه جستجوی ملک',
    h1: " مکان های دیدنی تهران و 9 جاذبه گردشگری همراه با عکس و آدرس",
    desc: "مکان های دیدنی تهران از جمله جاذبه های طبیعی و تاریخی، هر ساله گردشگران زیادی را به سمت خود جذب می کنند.",
    author: "نیلوفر پورقلی",
    date: "2022/08/19",
    image: articleImage10,
    link: "/Tehran-spectaculars",
    tags: ["دانستنی ها"],
    access: []
  },
  {
    title: 'تمام اطلاعاتی که برای نگهداری گیاه در آپارتمان باید بدانید | راهنمای کامل نگهداری و پرورش گیاه در خانه | بهترین گیاهان آپارتمان | مقالات مربوط به خانه و زندگی | هومینجا تنها راه خانه دارشدن ',
    h1: "نکاتی که در مورد نگهداری گیاهان آپارتمانی باید بدانیم",
    desc: "نگه داری گیاهان آپارتمانی با هر هدفی نیازمند رعایت یکسری اصول می باشد. هومینجا به شما کمک می کند فضای سبزی بی نظیر در خانه داشته باشید.",
    author: "نیلوفر پورقلی",
    date: "2021/01/24",
    image: articleImage11,
    link: "/keeping-apartment-flower",
    tags: ["دانستنی ها"],
    access: []
  },
  {
    title: 'زیر و بم طراحی اتاق لباسشویی در منزل | همه چیز در مورد کاربرد و طراحی لاندری روم در آپارتمان | بررسی اتاق لاندری در منزل | بهترین مقالات طراحی و دکوراسیون منزل در ایران | هومینجا سایت خرید آپارتمان لوکس در تهران',
    h1: "لاندری روم چیست، کاربردهای اتاق لباسشویی در منزل",
    desc: " لاندری روم یک واژه انگلیسی است و به اتاق لباسشویی یا رختشورخانه اشاره دارد.فضای کوچکی که به شستن و خنک کردن لباس اختصاص می دهیم.",
    author: "نیلوفر پورقلی",
    date: "2022/06/28",
    image: articleImage12,
    link: "/laundry-room",
    tags: ["معماری و دکوراسیون"],
    access: []
  },
  {
    title: 'همه انچه در مورد دیوار شاخص در منزل باید بدانید | طراحی و ساخت اکسنت وال در آپارتمان | بهترین سایت در حوزه طراحی داخلی و دکوراسیون خانه | هومینجا بهترین سایت ملکی در ایران',
    h1: "اکسنت وال چیست؟ تاثیر دیوار شاخص در دکوراسیون داخلی منزل",
    desc: "زمانیکه یک دیوار نسبت به بقیه دیوارهای اتاق متفاوت است اکسنت وال گفته می شود. با رنگ آمیزی یا کاغذ دیواری می توان دیوار را برجسته کرد.",
    author: "نیلوفر پورقلی",
    date: "2022/08/19",
    image: articleImage13,
    link: "/accent-wall",
    tags: ["تکنولوژی و فناوری"],
    access: []
  },
  {
    title: 'بررسی کامل محله پیروزی به همراه امکانات، عکس و نقشه | راهنمای قیمت آپارتمان در خیابان پیروزی | انواع معامله ملک در پیروزی تهران | رهن و اجاره آپارتمان در محله پیروزی تهران | هومینجا بهترین وب سایت خرید و فروش املاک در پیروزی',
    h1: "خیابان پیروزی و بررسی کامل یکی از محبوب ترین محله های شرق تهران",
    desc: "آشنایی با امکانات ، سطح دسترسی و قیمت ملک در خیابان پیروزی که یکی از قدیمی ترین محلات شرق تهران می باشد.",
    author: "نیلوفر پورقلی",
    date: "2021/01/24",
    image: articleImage14,
    link: "/Piroozi-street",
    tags: ["محله گردی"],
    access: []
  },
  {
    title: 'راهنمای خرید ملک در تهران | ترفندهای خرید آپارتمان در تهران | بهترین روش خرید خانه در شهر تهران | هر آنچه در مورد خرید املاک در تهران باید بدانید | هومینجا بهترین سایت خرید ملک در تهران ',
    h1: "نکات مهم در مورد خرید ملک در تهران",
    desc: "خرید و فروش ملک در تهران یک کار کاملاً تخصصی است. ما در این مقاله از هومینجا به نکات مهم خرید و فروش ملک در تهران پرداخته ایم.",
    author: "نیلوفر پورقلی",
    date: "2021/01/24",
    image: articleImage15,
    link: "/buying-house-in-Tehran",
    tags: ["مسائل حقوقی ملک"],
    access: []
  },
  {
    title: 'بررسی کامل محله شمیران نو به همراه امکانات، عکس و نقشه | راهنمای قیمت آپارتمان در شمیران نو | انواع معامله ملک در شمیران نو تهران | رهن و اجاره آپارتمان در محله شمیران نو تهران | هومینجا بهترین وب سایت خرید و فروش املاک در شمیران نو',
    h1: "محله شمیران نو و هر آنچه در مورد این محله باید بدانید",
    desc: "اگر قصد خرید ملک در شمیران نو را دارید ما در هومینجا به شما کمک می کنیم تا با آشنایی نسبی با این منطقه انتخاب بهتری داشته باشید.",
    author: "نیلوفر پورقلی",
    date: "2021/01/24",
    image: articleImage16,
    link: "/Shemiran-No",
    tags: ["محله گردی"],
    access: []
  },
  {
    title: 'معرفی و بررسی کامل بهترین کافی شاپ های کتاب در تهران به همراه آدرس و تلفن | لیست دنج ترین کافه کتاب تهران به همراه عکس | بهترین سایت معرفی کافه و رستوران هومینجا',
    h1: "کافه کتاب و معرفی بهترین کافی شاپ های کتاب در تهران",
    desc: "کافه همیشه برای دورهمی نیست. گاهی لازم است تا ادم خلوت کند و در این خلوت تنها کتاب را راه می دهد. کافه کتاب مکانی برای عاشقان کتاب است.",
    author: "نیلوفر پورقلی",
    date: "2022/07/23",
    image: articleImage17,
    link: "/book-cafe",
    tags: ["دانستنی ها"],
    access: []
  },
  {
    title: 'بررسی کامل محله حکیمیه به همراه امکانات، عکس و نقشه | راهنمای قیمت آپارتمان در حکیمیه | انواع معامله ملک در حکیمیه تهران | رهن و اجاره آپارتمان در محله حکیمیه  تهران | هومینجا بهترین وب سایت خرید و فروش املاک در حکیمیه',
    h1: "محله حکیمیه و بررسی کامل شرقی ترین محله تهران",
    desc: "حکیمیه از شرقی ترین مناطق تهران است ودارای آب و هوای بسیار مطلوبی می باشد.با هومینجا همراه باشید تا اطلاعات بیشتری از این محله بدست آورید.",
    author: "نیلوفر پورقلی",
    date: "2021/01/24",
    image: articleImage18,
    link: "/Hakimiyeh",
    tags: ["محله گردی"],
    access: []
  },
  {
    title: 'بررسی کامل محله آزادی به همراه امکانات، عکس و نقشه | راهنمای قیمت آپارتمان در آزادی | انواع معامله ملک در آزادی تهران | رهن و اجاره آپارتمان در محله آزادی تهران | هومینجا بهترین وب سایت خرید و فروش املاک در آزادی',
    h1: "محله آزادی و هر آنچه که در مورد این محله خوش مسیر باید بدانید",
    desc: "ما در اینجا به معرفی محله آزادی که از پرتردد ترین خیابان های تهران می باشد می پردازیم. با ما همراه شوید تا بیشتر با این خیابان آشنا شوید",
    author: "نیلوفر پورقلی",
    date: "2021/01/24",
    image: articleImage19,
    link: "/Azadi",
    tags: ["محله گردی"],
    access: []
  },
  {
    title: 'بررسی ترفند ها و راهکارهای ساده برای اثاث کشی راحت | همه چیز در مورد اسباب کشی آسان و راحت | بهترین مقالات خانه و زندگی | بهترین سایت درج آگهی ملکی در ایران',
    h1: "فوت و فن اثاث کشی راحت",
    desc: "از نکات مهم در اثاث کشی صرف کمترین زمان و انرژی و کمترین میزان خسارت به وسایل و حتی افراد با رعایت بهترین نظم می باشد.",
    author: "نیلوفر پورقلی",
    date: "2021/01/24",
    image: articleImage20,
    link: "/moving-the-house",
    tags: ["خانه و سبک زندگی"],
    access: []
  },
  {
    title: 'معرفی و بررسی انواع روش های کلاهبرداری در زمینه معاملات ملک | انواع کلاهبرداری در خرید و فروش ملک | روشهای مرسوم کلاهبرداری در حوزه املاک و مستغلات | هومینجا مطمئن ترین سایت معاملاک املاک در تهران',
    h1: "انواع کلاهبرداری در معاملات ملک",
    desc: "برای جلوگیری از کلاهبرداری ملک چه باید کنیم؟ در هومینجا ما شما را راهنمایی می کنیم تا از به دام افتادن توسط افراد فرصت طلب جلوگیری کنیم",
    author: "نیلوفر پورقلی",
    date: "2021/01/24",
    image: articleImage21,
    link: "/real-estate-fraud",
    tags: ["مسائل حقوقی"],
    access: []
  },
  {
    title: 'بررسی شرایط، مدارک و مراحل اخذ جواز ساختمان | زیر و بم دریافت پروانه ساختمان | آشنایی کامل با صدور مجوز پروانه ساختمان | بهترین مقالات حوزه املاک و مستغلات هومینجا ',
    h1: "همه چیز راجع به صدور پروانه ساختمان",
    desc: "هر نوع ساخت وسازی در ایران تنها در صورتی مجاز است که پروانه ساخت داشته باشد که صدور پروانه ساخت از سوی شهرداری محل اقامت می باشد.",
    author: "نیلوفر پورقلی",
    date: "2021/01/23",
    image: articleImage22,
    link: "/building-certificate",
    tags: ["مسائل حقوقی"],
    access: []
  },
  {
    title: 'مراحل و شرایط سند زدن ملک از ابتدا تا انتها | مدارک و نکات سند زدن خانه و آپارتمان | صفر تا صد صدور سند خانه و مراحل اخذ آن | هومینجا جامع ترین سایت اطلاعات ملکی',
    h1: "مراحل سند زدن ملک و تمام نکات مربوط به صدور سند ملک",
    desc: "سند زدن ملک اقدامی بسیار حساس است. بسیاری از خریداران و فروشندگان به دلیل عدم آگاهی از مراحل سند زدن ملک با مشکلاتی مواجه میشوند.",
    author: "نیلوفر پورقلی",
    date: "2022/08/23",
    image: articleImage23,
    link: "/transferring-property",
    tags: ["مسائل حقوقی"],
    access: []
  },
  {
    title: 'دانستنی و مطالب جالب در مورد املاک عجیب دنیا | اطلاعات املاک باورنکردنی و دیدنی دنیا | معرفی مکان ها و خانه های خاص دنیا | هومینجا بهترین سایت معرفی و فروش املاک درایران',
    h1: "دانستنی های جالب املاک",
    desc: "خیلی از شما بدنبال شنیدن حقایق عجیب و شگفت انگیز در مورد ملک هستید. ما در هومینجا به تعدادی از این دانستنی های جالب می پردازیم",
    author: "نیلوفر پورقلی",
    date: "2022/09/12",
    image: articleImage24,
    link: "/real-estate-interesting-things",
    tags: ["دانستنی ها"],
    access: []
  },
  {
    title: 'صفر تا صد کد رهگیری املاک | دریافت و استعلام کد رهگیری معاملات ملک | سامانه استعلام و اخذ کد رهگیری قرارداد اجاره | بهترین سایت استعلام و دریافت کد رهگیری در تهران',
    h1: "کد رهگیری املاک چیست؟",
    desc: "پس از ثبت قرارداد در سامانه معاملات و املاک کشوریک کد رهگیری 13 رقمی منحصر به فرد جهت جلوگیری از جرایم معاملات ملکی صادر می شود.",
    author: "نیلوفر پورقلی",
    date: "2021/01/24",
    image: articleImage25,
    link: "/house-tracking-code",
    tags: ["مسائل حقوقی"],
    access: []
  },
  {
    title: 'همه چیز در مورد شغل مشاور املاک | بررسی کامل شرایط، درآمد و نکات کسب و کار مشاور املاک | روش های تبدیل به مشاور املاک به زبان ساده | سامانه مشاوران املاک هومینجا',
    h1: "مشاور املاک کیست و بررسی عمومی شرایط شغل مشاورین ملک",
    desc: "در ایران به اشخاصی که در حوزه معاملاک املاک فعالیت میکنند مشاور املاک گفته می شود که در این مقاله به بیان برخی از وظایف آنان می پردازیم.",
    author: "نیلوفر پورقلی",
    date: "2022/10/1",
    image: articleImage26,
    link: "/real-estate",
    tags: ["مشاورین املاک"],
    access: []
  },
  {
    title: 'مشاور املاک حرفه ای کیست | بررسی شرایط و نکات ریز مشاوران حرفه ای املاک با درآمد بالا | ویژگی ها و فنون مشاور املاک متخصص و حرفه ای | سامانه حرفه ای مشاوران املاک هومینجا',
    h1: "چطور یک مشاور املاک حرفه ای شویم؟",
    desc: "نحوه صحبت و مذاکره شما را از مشاور معمولی به مشاور املاک حرفه ای تبدیل می کند. پس با هومینجا همراه باشید تا شما را با این فنون اشنا کنیم.",
    author: "نیلوفر پورقلی",
    date: "2022/09/28",
    image: articleImage27,
    link: "/professional-real-estate",
    tags: ["مشاورین املاک"],
    access: []
  },
  {
    title: 'بررسی کامل ورود و ثبت نام در سامانه ملی املاک و اسکان کشور | سامانه ملی معاملات املاک و مستغلات کشور | سامانه ملی ثبت معاملات املاک و اجاره هومینجا',
    h1: "معرفی سامانه ملی املاک و اسکان کشور",
    desc: "اوایل سال 1400 سامانه ملی املاک و اسکان کشور با هدف ثبت و ذخیره اطلاعات مرتبط با حوزه اسکان و املاک در ایران راه اندازی شد .",
    author: "نیلوفر پورقلی",
    date: "2022/11/28",
    image: articleImage28,
    link: "/amlak.mrud.ir",
    tags: ["مسائل حقوقی"],
    access: []
  },
  {
    title: 'معرفی بهترین مکان های تفریحی تهران در زمستان | زمستان در تهران کجا بریم | تهران گردی در فصل زمستان همراه با ادرس و عکس | بهترین سایت معرفی مکان های دیدنی در تهران هومینجا',
    h1: "تفریحات زمستانی تهران",
    desc: "تهران شهری چهارفصل است که گشت‌وگذار در آن بخصوص در فصل زمستان می‌تواند یکی از لذت‌بخش‌ترین تفریحات برای افراد باشد.",
    author: "نیلوفر پورقلی",
    date: "2022/12/17",
    image: articleImage29,
    link: "/tehran-winter-entertaiments",
    tags: ["دانستنی ها"],
    access: []
  },
  {
    title: 'لیست بهترین و خوشمزه ترین رستوران های منطقه یک شهرداری تهران | معرفی بهترین غذاخوری در منطقه 1 شهر تهران | معرفی رستوران منطقه 1 | هومینجا بهترین سایت معرفی رستوران در تهران',
    h1: "بهترین رستوران های منطقه 1 تهران",
    desc: "اگر برای صرف یک غذای خوشمزه دنبال یک رستوران عالی هستید ما دراین مقاله از هومینجا شما را با بهترین رستوران های منطقه 1 آشنا می کنیم.",
    author: "نیلوفر پورقلی",
    date: "2023/01/02",
    image: articleImage30,
    link: "/best-restaurant-tehran-region1",
    tags: ["دانستنی ها"],
    access: []
  },
  {
    title: 'بررسی انواع مدل نما به همراه عکس با کیفیت | نکات طراحی و اجرای انواع نما ساختمان و آپارتمان | لیست انواع مختلف نمای ساختمان با عکس و جزئیات | بهترین مطالب صنعت ساختمان هومینجا',
    h1: "معرفی انواع نمای ساختمان",
    desc: "نمای ساختمان اولین المان مهم یک بنا می باشد که پوسته هر ساختمان را تشکیل می دهد. نما نقش بسیار مهمی در زیبایی و جذابیت ساختمان ایفا میکند.",
    author: "نیلوفر پورقلی",
    date: "2023/01/03",
    image: articleImage31,
    link: "/building-facades-types",
    tags: ["معماری و دکوراسیون"],
    access: []
  },
  {
    title: 'بررسی طراحی و دکوراسیون داخلی منزل با متراژ کم | دکوراسیون داخلی منازل کوچک مدرن و شیک | ترفندهای دکور آپارتمان های کوچک | بهترین سایت طراحی و دکوراسیون منزل هومینجا',
    h1: "دکوراسیون خانه های کوچک",
    desc: " دکوراسیون خانه های کوچک یک چالش برای افراد خانه است که ما در این مطلب از هومینجا به بررسی ایده های کاربردی، برای دکوراسیون می پردازیم.",
    author: "نیلوفر پورقلی",
    date: "2023/01/04",
    image: articleImage32,
    link: "/small-house-decoration",
    tags: ["معماری و دکوراسیون"],
    access: []
  },
  {
    title: 'معرفی امکانات، مزایا و معایب اسمارت هوم | صفر تاصد خانه هوشمند و هوشمند سازی منزل | خانه هوشمند یعنی چه و چه اقداماتی برای هوشمند سازی خانه نیاز است | تجهیزات خانه هوشمند | بهترین سایت خانه های هوشمند درتهران',
    h1: "خانه هوشمند و صفر تا صد هوشمندسازی آپارتمان و ویلا",
    desc: " اسمارت هوم یا خانه هوشمند سیستمی است که به شما اجازه می دهد از راه دور خانه را کنترل کنید و بر سیستم امنیتی نظارت داشته باشید.",
    author: "نیلوفر پورقلی",
    date: "2023/01/07",
    image: articleImage33,
    link: "/smart-home",
    tags: ["تکنولوژی و فناوری"],
    access: []
  },
  {
    title: 'بررسی پاف مبل راحتی و بین بگ | انتخاب و خرید انواع مدل پاف ارزان | راهنمای خرید پاف در تهران | پاف از کجا بخریم | بهترین سایت دکوراسیون و طراحی داخلی منزل هومینجا',
    h1: "پاف چیست؟ معرفی یکی از جذاب ترین انواع مبلمان",
    desc: " پاف چیست؟ نشیمنی کم ارتفاع است که از لحاظ نرمی و سبکی شبیه کوسن می باشد. پاف معمولا بدون پایه می باشد و فضای صندوقچه ای ندارد.",
    author: "نیلوفر پورقلی",
    date: "2023/01/09",
    image: articleImage34,
    link: "/pouf",
    tags: ["معماری و دکوراسیون"],
    access: []
  },
  {
    title: 'همه نکاتی که برای پیش خرید آپارتمان نیاز دارید | پیش فروش آپارتمان مسکونی و پیش خرید ملک اداری و مغازه تجاری | نکات ضروری قبل از پیش خرید و پیش فروش ساختمان | بهترین سایت آگهی پیش فروش ساختمان',
    h1: "نکات ضروری برای پیش خرید و پیش فروش آپارتمان",
    desc: " دراین مقاله از سایت املاک هومینجا قصد داریم تا به معرفی کلی نکات و مقررات مربوط به پیش خرید و پیش فروش آپارتمان، خانه و انواع ملک بپردازیم.",
    author: "نیلوفر پورقلی",
    date: "2023/01/14",
    image: articleImage35,
    link: "/apartment-pre-sale",
    tags: ["مسائل حقوقی"],
    access: []
  },

  {
    title: 'صفر تا صد بررسی طرح نهضت ملی مسکن | بررسی کامل طرح ویژه مسکن مهر | طرح نهضت ملی مسکن سامانه جامع طرح های حمایتی مسکن | ثبت نام طرح نهضت ملی مسکن 1402 و 1401 | سامانه ثمن نهضت ملی مسکن هومینجا',
    h1: "همه چیز در مورد طرح نهضت ملی مسکن و مسکن مهر",
    desc: " مسکن مهر و طرح نهضت ملی مسکن، برای اقشار مختلف جامعه به ویژه آن‌هایی که توانایی خرید منزل مناسب ندارند، تدارک دیده شده است.",
    author: "نیلوفر پورقلی",
    date: "2023/01/18",
    image: articleImage36,
    link: "/mehr-house",
    tags: ["بازار مسکن"],
    access: []
  },
  {
    title: 'بررسی کامل محله نیاوران به همراه امکانات، عکس و نقشه | راهنمای قیمت آپارتمان در نیاوران | انواع معامله ملک در نیاوران تهران | رهن و اجاره آپارتمان در محله نیاوران تهران | هومینجا بهترین وب سایت خرید و فروش املاک در نیاوران',
    h1: "معرفی کامل محله نیاوران تهران",
    desc: " شاید خیلی از شما قصد خرید یا اجاره ملک در محله نیاوران تهران را داشته باشید اما آشنایی زیادی با این منطقه نداشته باشید.",
    author: "نیلوفر پورقلی",
    date: "2023/01/21",
    image: articleImage37,
    link: "/niavaran-tehran",
    tags: ["محله گردی"],
    access: []
  },
  {
    title: 'همه چیز در مورد سرقفلی مغازی تجاری | حق سرقفلی تجاری ملک و مغازی | تفاوت مالکیت و سرقفلی | زیر و بم حق کسب پیشه مغازه تجاری | هومینجا بهترین سایت خرید مفازه و فروش ملک تجاری',
    h1: "سرقفلی مغازه و تمام نکاتی که باید در نظر گرفت",
    desc: "سرقفلی مغازه حقی است که قانون گذار برای مستاجرین مراکز و املاک تجاری تعیین کرده تا به نوعی آن‌ها را تحت حمایت و حفاظت خود قرار داده باشد. ",
    author: "نیلوفر پورقلی",
    date: "2023/02/05",
    image: articleImage38,
    link: "/Business-Right",
    tags: ["مسائل حقوقی"],
    access: []
  },
  {
    title: 'بهترین طراحی دکوراسیون اتاق کودک همراه با عکس نمونه | تزئین و چیدمان اتاق بچه کوچک | انواع مدل طراحی دکوراسیون اتاق کودک پسر و کودک دختر | هومینجا بهترین سایت طراحی و دکوراسیون منزل',
    h1: "نکات جالب در مورد دکوراسیون اتاق کودک",
    desc: "از آنجایی که کودکان روحیه حساس و لطیفی دارند و وقت زیادی را در اتاق خواب خود می گذرانند بنابراین دکوراسیون اتاق کودک بسیار مهم می باشد.",
    author: "نیلوفر پورقلی",
    date: "2023/02/05",
    image: articleImage39,
    link: "/children-room-decoration",
    tags: ["معماری و دکوراسیون"],
    access: []
  },
  {
    title: 'چشم انداز آینده بازار مسکن در سال 1402 | معرفی عوامل افزایش قیمت مسکن در سال 1402 | پیش بینی قیمت ها در شش ماه بازار مسکن در تهران | هومینجا بهترین سایت خرید و اجاره ملک در تهران',
    h1: "پیش بینی بازار مسکن در سال ۱۴۰۲ : کاهش یا افزایش قیمت",
    desc: " این روزها آگاهی از افزایش و یا کاهش قیمت در بخش مسکن اهمیت زیادی پیدا کرده و پیش‌بینی آینده این بازار در سال ۱۴۰۲ برای عده زیادی مهم است.",
    author: "نیلوفر پورقلی",
    date: "2023/02/12",
    image: articleImage40,
    link: "/Housing-Market-Prediction-1402",
    tags: ["مسائل حقوقی"],
    access: []
  },
  {
    title: 'بررسی کامل مراحل خانه تکانی عید نوروز باستانی | قدم به قدم با نکات و ترفندهای عید برای خانه دارها و شاغلین | راهنمای کامل خانه تکانی عید از لیست کارها تا وسایل ضروری | هومینجا بهترین سایت راهنمای خانه و سبک زندگی',
    h1: "خانه تکانی عید را چطور و از کجا شروع کنیم ؟",
    desc: "در این مطلب از هومینجا سعی داریم برای انجام خانه تکانی عید در کمترین زمان ممکن تمامی نکات و ترفندهای مربوط به آن را بازگو کنیم.",
    author: "نیلوفر پورقلی",
    date: "2023/02/14",
    image: articleImage41,
    link: "/Spring-Cleaning",
    tags: ["خانه و سبک زندگی"],
    access: []
  },
  {
    title: 'شیک ترین و معروف ترین برج های مسکونی منطقه یک تهران را بشناسید | لاکچری ترین و گران قیمت ترین ساختمان های مسکونی منطقه یک تهران | هومینجا بهترین سایت خرید برج لوکس و آپارتمان مدرن',
    h1: "معرفی برج های لوکس منطقه 1 تهران",
    desc: "در این مطلب از هومینجا به معرفی ده مورد از برج های لوکس منطقه 1 تهران می پردازیم. هر کدام بدلیل امکانات منحصر به فردی که دارند معروف هستند.",
    author: "نیلوفر پورقلی",
    date: "2023/02/20",
    image: articleImage42,
    link: "/Tehran-District-One-Luxurious-Towers",
    tags: ["دانستنی ها"],
    access: []
  },
  {
    title: 'اخذ جواز کسب مشاورین املاک به همراه مدارک و شرایط | راهنمای کامل اخذ مجوز تاسیس املاک | مدارک لازم جهت دریافت پروانه کسب املاک | هومینجا بهترین آژانس آنلاین املاک در تهران',
    h1: "جواز کسب آژانس املاک چیست و چگونه دریافت کنم؟",
    desc: "دریافت مجوز املاک یک فرآیند نسبتاً سریع و مقرون به صرفه است و شما با دانستن مراحل این کار، می توانید برای دریافت آن اقدام کنید.",
    author: "نیلوفر پورقلی",
    date: "2023/02/25",
    image: articleImage43,
    link: "/Real-Estate-Agency-License",
    tags: ["مسائل حقوقی"],
    access: []
  },
  {
    title: 'بررسی صفر تا صد امکانات و دسترسی های محله لوکس و مدرن الهیه و خیابان فرشته تهران | راهنمای قیمت آپارتمان در الهیه | انواع معامله ملک در الهیه و فرشته تهران | رهن و اجاره آپارتمان در محله الهیه و خیابان فرشته تهران | هومینجا بهترین وب سایت خرید و فروش املاک در الهیه و فرشته تهران',
    h1: "الهیه تهران کجاست ؟  معرفی کامل خیابان فرشته تهران",
    desc: "خیابان فرشته یکی از لاکچری‌ترین خیابان‌های تهران می باشد و معروفیت آن بیشتر برای برج‌های مسکونی و تجاری و ویلاهای لوکس و گران‌قیمت آن است.",
    author: "نیلوفر پورقلی",
    date: "2023/02/27",
    image: articleImage44,
    link: "/Elahiyeh-Neighborhood-Fereshteh-Street",
    tags: ["محله گردی"],
    access: []
  },
  {
    title: 'بررسی صفر تا صد امکانات و دسترسی های محله چیتگرتهران | راهنمای قیمت آپارتمان در چیتگر | انواع معامله ملک در چیتگر تهران | رهن و اجاره آپارتمان در محله چیتگر تهران | هومینجا بهترین وب سایت خرید و فروش املاک در چیتگر تهران',
    h1: "چیتگر تهران کجاست؛ تمام اطلاعاتی که در مورد محله چیتگر نیاز دارید",
    desc: "بدلیل شهرک های جدید و مدرن و برج های بلندی که چیتگر ساخته شده اند این محله معروف است. ما در هومینجا به معرفی این منطقه می پردازیم.",
    author: "نیلوفر پورقلی",
    date: "2023/03/12",
    image: articleImage45,
    link: "/Chitgar-Neighborhood",
    tags: ["محله گردی"],
    access: []
  },
  {
    title: 'اصول طراحی داخلی خانه با متدهای جدید | طراحی و بازسازی دکوراسیون و دکور داخلی منزل مسکونی | اصول معماری داخلی در صنعت ساختمان | هومینجا بهترین سایت معماری و طراحی داخلی',
    h1: " معماری داخلی و طراحی داخلی چیست و چه تفاوتی دارند",
    desc: "با وجود تمام تفاوت هایی که بین معماری داخلی و طراحی داخلی وجود دارد اما هر دو مکمل یکدیگر هستند و به بهبود فضا کمک می کنند.",
    author: "نیلوفر پورقلی",
    date: "2023/03/14",
    image: articleImage46,
    link: "/Interior-architecture-and-interior-design",
    tags: ["معماری و دکوراسیون"],
    access: []
  },
  {
    title: 'معرفی کامل لیست مشاور املاک زن موفق و ثروتمند | مجوز املاک خانم ها | بررسی نکات و ترفندها برای موفقیت خانم ها در شغل مشاور املا ک | بانوان موفق و پولدار مشاور املاک | هومینجا بهترین سایت مشاورین املاک خانم در تهران',
    h1: " زنان مشاور املاک موفق",
    desc: "در این مقاله از هومینجا به این مطلب می پردازیم که زنان مشاور املاک چه نکاتی را باید رعایت کنند تا در حرفه خود بعنوان بهترین ظاهر شوند.",
    author: "نیلوفر پورقلی",
    date: "2023/03/15",
    image: articleImage47,
    link: "/Successful-Women-Real-Estate",
    tags: ["مشاورین املاک"],
    access: []
  },
  {
    title: 'بهترین وب سایت خرید و فروش ملک ، آپارتمان، برج، اداری و تجاری | معتبرترین سایت معاملات املاک و مستغلات در ایران | بزرگترین فایلینگ املاک تهران | هومینجا اولین سایت تخصصی مسکن در تهران',
    h1: " ۵ سایت برتر خرید و فروش املاک، آپارتمان، خانه",
    desc: "هومینجا؛سامانه انلاین املاک به معرفی 5 سایت برتر املاک که انواع معاملات خرید و فروش یا رهن و اجاره انواع ملک را پشتیبانی میکنند، میپردازد.",
    author: "نیلوفر پورقلی",
    date: "2023/04/04",
    image: articleImage48,
    link: "/top-5-real-estate-sites",
    tags: ["بازار مسکن"],
    access: []
  },
  {
    title: 'بررسی کامل صفر تا صد امکانات، دسترسی ها و محله های منطقه 22 شهرداری تهران | راهنمای کامل قیمت آپارتمان و ملک در منطقه 22 تهران | خرید و اجاره آپارتمان در منطقه 22 | بهترین سایت خرید و فروش، رهن و اجاره آپارتمان در منطقه 22',
    h1: "منطقه ۲۲ تهران ؛ معرفی محله ها، شهرک ها، امکانات و بررسی قیمت آپارتمان ",
    desc: "منطقه ۲۲ در شمال غربی تهران واقع است و غربی ترین و جدیدترین منطقه شهرداری تهران محسوب می شود. این منطقه به قطب گردشگری تبدیل شده است. ",
    author: "نیلوفر پورقلی",
    date: "2023/04/05",
    image: articleImage49,
    link: "/tehran-district-22",
    tags: ["محله گردی"],
    access: []
  },
  {
    title: 'معرفی بهترین رستوران ها و کافه ها برای افطاری و ضیافت افطار در ماه رمضان | برترین و جذاب ترین رستوران و کافه برای عید فطر و افطاری | هومینجا بهترین سایت معرفی کافه و رستوران در تهران',
    h1: " بهترین کافه ها از افطار تا سحر در تهران",
    desc: "در این مقاله از هومینجا برآنیم تا شما را با بهترین کافه و رستوران هایی که از افطار تا سحر آماده پذیرایی از روزه داران هستند آشنا کنیم. ",
    author: "نیلوفر پورقلی",
    date: "2023/04/15",
    image: articleImage50,
    link: "/best-cafes-iftar-til-suhur",
    tags: ["دانستنی ها"],
    access: []
  },
  {
    title: 'بررسی کامل محله اندرزگو به همراه امکانات، عکس و نقشه | راهنمای قیمت آپارتمان در اندرزگو | انواع معامله ملک در اندرزگو تهران | رهن و اجاره آپارتمان در بلوار اندرزگو تهران | هومینجا بهترین وب سایت خرید و فروش املاک در اندرزگو',
    h1: " خیابان اندرزگو تهران | معرفی کامل اندرزگو با تصویر و نقشه",
    desc: "بلوار اندرزگو از خیابان های شمال شرق تهران می باشد که در منطقه یک شهرداری تهران واقع است و به محلی برای دور دور قشر جوان تبدیل شده است. ",
    author: "نیلوفر پورقلی",
    date: "2023/04/19",
    image: articleImage51,
    link: "/Andarzgoo-blvd",
    tags: ["محله گردی"],
    access: []
  },
  {
    title: 'تخصصی ترین وب سایت املاک و مستغلات در ایران | بهترین سایت جستجوی آگهی ملک | بزرگترین سایت درج آگهی ملک | بهترین سایت رهن و اجاره خانه، آپارتمان | برترین سایت رهن و اجاره ملک ، آپارتمان و برج در تهران',
    h1: " هومینجا؛ سایت آنلاین تخصصی خرید و فروش، رهن و اجاره املاک و مستغلات",
    desc: "در این مقاله قصد داریم تا شما را با بهترین سایت تخصصی املاک و مستغلات ؛ هومینجا آشنا کنیم و انواع خدمات آن را به صورت مختصر توصیح دهیم. ",
    author: "نیلوفر پورقلی",
    date: "2023/04/23",
    image: articleImage52,
    link: "/Best-Iranian-Real-Estate-Site",
    tags: ["تکنولوژی و فناوری"],
    access: []
  },
  {
    title: 'هزینه ساخت هر متر ساختمان در تهران | پیش بینی هزینه ساخت مسکن در تهران | محاسبه هزینه ساخت و ساز هر متر مربع واحد مسکونی و آپارتمانی | بهترین سایت خرید ملک نوساز در تهران',
    h1: " برآورد هزینه ساخت ساختمان در تهران سال ۱۴۰۲",
    desc: "برآورد هزینه ساخت خانه از مواردی است که برای ساخت آپارتمان و خانه‌های ویلایی مهم است که ما در هومینجا به بررسی کامل این موارد می پردازیم.",
    author: "نیلوفر پورقلی",
    date: "2023/04/26",
    image: articleImage53,
    link: "/1402-Tehran-Construction-Cost",
    tags: ["بازار مسکن"],
    access: []
  },
  {
    title: 'بررسی کامل محله فرمانیه به همراه امکانات، عکس و نقشه | راهنمای قیمت آپارتمان در فرمانیه | انواع معامله ملک در فرمانیه تهران | رهن و اجاره آپارتمان در بلوار فرمانیه تهران | هومینجا بهترین وب سایت خرید و فروش املاک در فرمانیه ',
    h1: " فرمانیه تهران؛ معرفی کامل این محله همراه با عکس و نقشه",
    desc: "محله فرمانیه تهران که به پنت هاوس های لوکس شناخته می شود در منطقه یک شهرداری تهران قرار دارد و بهشت برج های لوکس تهران نیز نامیده می شود. ",
    author: "نیلوفر پورقلی",
    date: "2023/05/03",
    image: articleImage54,
    link: "/Farmanieh-Neighborhood",
    tags: ["محله گردی"],
    access: []
  },
  {
    title: 'انواع وام مسکن و شرایط دریافت تسهیلات در سال 1402 ( همه بانک ها) | شرایط و نحوه دریافت وام خرید مسکن از تمام بانک ها | معرفی کامل شرایط و نحوه ثبت نام وام مسکن 1402 | هومینجا بهترین سایت اطلاع رسانی اخبار ملک',
    h1: " وام مسکن ۱۴۰۲؛ شرایط و نحوه دریافت انواع وام خانه",
    desc: "باتوجه به درخواست بالای مردم برای دریافت وام مسکن بر آن شدیم تا در این مقاله شما را با شرایط و مدارک لازم جهت دریافت وام مسکن ۱۴۰۲ آشنا کنیم. ",
    author: "نیلوفر پورقلی",
    date: "2023/05/05",
    image: articleImage55,
    link: "/1402-House-Loan",
    tags: ["بازار مسکن"],
    access: []
  },
  {
    title: 'چگونه فضای خانه و محل کار را خوشبو کنیم | خوشبو کردن محیط داخلی منزل با روش های راحت و طبیعی | روش های رفع بوی بد منزل و ایجاد هوای تازه | هومینجا بهترین سایت خانه و سبک زندگی',
    h1: " خوشبو کردن خانه با روش های آسان و طبیعی",
    desc: "خوشبو کردن منزل علاوه بر استشمام رایحه مطبوع بر آرامش ذهنی افراد نیز تاثیرگذار است. ما در اینجا به خوشبو کردن خانه با ترکیبات طبیعی می پردازیم. ",
    author: "نیلوفر پورقلی",
    date: "2023/05/08",
    image: articleImage56,
    link: "/Home-Freshener",
    tags: ["خانه و سبک زندگی"],
    access: []
  },
  {
    title: 'بررسی صفر تا صد اینترنت اشیا | مفاهیم، کاربرد، مزایا و معایب اینترنت اشیا | تاریچه و زیر وبم فناوری اینترنت اشیا | انواع مختلف اینترنت اشیا و بستر انتقال اطلاعات | هومینجا بهترین سایت تکنولوژی ساختمان',
    h1: " اینترنت اشیا چیست (IOT) و چه کاربردهایی دارد ؟",
    desc: "اینترنت اشیا یعنی ارتباط دستگاه‌ها با اینترنت که از طریق این ارتباط کاربران دارای دسترسی مجاز به این شبکه، امکان کنترل این لوازم را دارند.",
    author: "نیلوفر پورقلی",
    date: "2023/05/13",
    image: articleImage57,
    link: "/IOT",
    tags: ["تکنولوژی و فناوری"],
    access: []
  },
  {
    title: 'بررسی کامل امکانات و مزایای ساختمان هوشمند و هوشمند سازی ساختمان | راهنمای کامل ساختمان هوشمند و هوشمند سازی ساختمان | هومینجا بهترین سایت فناوری های نوین ساختمان',
    h1: " ساختمان هوشمند چیست | همه چیز در مورد هوشمند سازی ساختمان",
    desc: "هوشمند سازی ساختمان امنیت و آسایش را برای ساکنین فراهم می آورد. در این مقاله سعی کردیم تا به معرفی ساختمان هوشمند و تجهیزات آن بپردازیم.",
    author: "نیلوفر پورقلی",
    date: "2023/05/17",
    image: articleImage58,
    link: "/Home-Automation",
    tags: ["تکنولوژی و فناوری"],
    access: []
  },
  {
    title: 'بررسی کامل شهرک اکباتان به همراه امکانات، عکس و نقشه | راهنمای قیمت آپارتمان در اکباتان | انواع معامله ملک در اکباتان تهران | رهن و اجاره آپارتمان در شهرک اکباتان تهران | هومینجا بهترین وب سایت خرید و فروش املاک در اکباتان',
    h1: " شهرک اکباتان | معرفی و بررسی کامل اکباتان تهران",
    desc: "ویژگی های شهرک اکباتان فراتر از توقع اغلب افراد است. ما در هومینجا تمام ویژگی‌ها و امکانات شهرک اکباتان را مورد بررسی قرار می دهیم.",
    author: "نیلوفر پورقلی",
    date: "2023/05/21",
    image: articleImage59,
    link: "/Ekbatan-Town",
    tags: ["محله گردی"],
    access: []
  },
  {
    title: 'بررسی کامل شرایط و نحوه عقد قرارداد مشارکت در ساخت جدید | صفر تا صد قوانین و مقررات مشارکت در ساخت و محاسبه بلاعوض | کلاهبرداری و دعاوی حقوقی مشارکت در ساخت | فرمول محاسبه آنلاین مشارکت در ساخت 1402 | هومینجا بهترین سایت اخبار ملکی',
    h1: " مشارکت در ساخت سال ۱۴۰۲",
    desc: "ما در این مقاله از هومینجا قصد داریم به تمام نکات پیرامون شرایط مشارکت در ساخت سال ۱۴۰۲ بپردازیم، با ما همراه باشید.",
    author: "نیلوفر پورقلی",
    date: "2023/05/31",
    image: articleImage60,
    link: "/Partnering-Construction-In-1402",
    tags: ["مسائل حقوقی"],
    access: []
  },
  {
    title: 'معرفی کامل کافه بازی ها در تهران به همراه آدرس و ساعت کار | بهترین بازی های کافه بردگیم در تهران | لیست کامل بهترین کافه بردگیم ها در تهران با عکس | هومینجا سایت خانه و سبک زندگی',
    h1: " کافه بردگیم؛ معرفی بهترین کافه های بازی در تهران",
    desc: "کافه های بردگیم مکان تفریحی مناسب برای سپری کردن لحظاتی هیجان انگیز برای علاقه مندان به بازی‌ و سرگرمی‌ می باشد.",
    author: "نیلوفر پورقلی",
    date: "2023/06/17",
    image: articleImage61,
    link: "/Best-Board-Game-Cafe-In-Tehran",
    tags: ["دانستنی ها"],
    access: [
      { link: "/best-cafes-iftar-til-suhur", text: "کافه افطار تا سحر" },
      { link: "/book-cafe", text: "کافه کتاب " },
      { link: "/Tehran-cafe-with-hooka", text: "کافه قلیان" },
    ]
  },
  {
    title: 'بررسی کامل زعفرانیه به همراه امکانات، عکس و نقشه | راهنمای قیمت آپارتمان در زعفرانیه | انواع معامله ملک در زعفرانیه تهران | رهن و اجاره آپارتمان در زعفرانیه تهران | هومینجا بهترین وب سایت خرید و فروش املاک در زعفرانیه',
    h1: " زعفرانیه تهران ؛ بررسی کامل شرایط زندگی و امکانات محله لوکس زعفرانیه",
    desc: "ما در این مقاله از هومینجا به بررسی کامل منطقه زعفرانیه تهران می پردازیم تا با این منطقه اعیان نشین و گران قیمت تهران آشنا شوید.",
    author: "نیلوفر پورقلی",
    date: "2023/06/27",
    image: articleImage62,
    link: "/Zaferanieh-Neighborhood",
    tags: ["محله گردی"],
    access: [
      { link: "/Listings/buy_tehran_valiasr", text: "خرید ملک در ولیعصر" },
      { link: "/Listings/buy_tehran_moghadasardabili", text: "خرید ملک در مقدس اردبیلی" },
      { link: "/Listings/buy_tehran_elahieh", text: "خرید ملک در الهیه" },
    ]
  },
  {
    title: 'تمرینات فاکنشنال چیست به همراه برنامه و معرفی بهترین مربی فانکشنال | آموزش حرکات فانکشنال ترینیگ برای خانوم ها | بررسی کامل ورزش فانکشنال ترنینگ زیر نظر بهترین مربی | هومینجا سایت ورزش و سلامتی',
    h1: " فانکشنال ترینینگ و بررسی کامل آن؛ معرفی بهترین مربی خانم فانکشنال",
    desc: "اگر از آن دسته از افرادی هستید که تایم کافی برای باشگاه رفتن ندارید می توانید ورزش فانکشنال را انتخاب کنید تا بدون دستگاه به هدف خود برسید. ",
    author: "نیایش تاجی ",
    date: "2023/07/12",
    image: articleImage63,
    link: "/What-Is-Functional-Training",
    tags: ["دانستنی ها"],
    access: []
  },
  {
    title: 'صفر تا صد بازسازی خانه قدیمی به همراه چک لیست و نکات مهم | هزینه بازسازی و نوسازی منزل و تغییر دکوراسیون داخلی | بررسی تفاوت بازسازی و نوسازی خانه و ویلای قدیمی | هومینجا بهترین سایت خرید و فروش خانه های قدیمی و کلنگی',
    h1: " صفر تا صد بازسازی خانه و نوسازی ساختمان",
    desc: "بازسازی خانه یا نوسازی منزل به فرآیند بهبود ساختار کهنه یا آسیب دیده ساختمان می گویند که معمولا در ساختمان های تجاری و مسکونی صورت می گیرد.",
    author: "نیلوفر پورقلی",
    date: "2023/07/31",
    image: articleImage64,
    link: "/what-is-house-renovation",
    tags: ["معماری و دکوراسیون"],
    access: []
  },
  {
    title: 'ترفندهای کاهش وزن و لاغری بدون بازگشت | برای لاغر شدن چی کار کنیم | سالم ترین و بهترین روش لاغری و تناسب اندام | راهکار های کاهش وزن و تناسب اندام | هومیجا سایت سلامت و ورزش',
    h1: " کاهش وزن اصولی و ترفندهای رسیدن به تناسب اندام",
    desc: "امروزه روش های زیادی برای کاهش وزن وجود دارد که باعث آسیب رساندن به بدن می شود. ما در این مقاله کاهش وزن اصولی را مورد بررسی قرار می دهیم. ",
    author: "نیایش تاجی",
    date: "2023/08/12",
    image: articleImage65,
    link: "/weight-loss-and-fitness",
    tags: ["دانستنی ها"],
    access: [
      { link: "/What-Is-Cardio-Exercises", text: "تمرینات کاردیو" },
      { link: "/What-Is-Functional-Training", text: "فانکشنال ترینینگ" },
    ]
  },
  {
    title: 'بررسی کامل کامرانیه به همراه امکانات، عکس و نقشه | راهنمای قیمت آپارتمان در کامرانیه | انواع معامله ملک در کامرانیه تهران | رهن و اجاره آپارتمان در کامرانیه تهران | هومینجا بهترین وب سایت خرید و فروش املاک در کامرانیه',
    h1: " معرفی کامل محله کامرانیه ؛ خیابانی مملو از برج های لوکس",
    desc: "کامرانیه یکی از گران قیمت ترین محله های منطقه یک تهران می باشد که ما در این مقاله به بررسی این خیابان و تمامی امکانات آن می پردازیم.",
    author: "تیم محتوای سورین",
    date: "2023/08/20",
    image: articleImage66,
    link: "/Kamranieh-Neighborhood",
    tags: ["محله گردی"],
    access: [
      { link: "/niavaran-tehran", text: "محله نیاوران" },
      { link: "/Andarzgoo-blvd", text: "محله اندرزگو" },
      { link: "/Farmanieh-Neighborhood", text: "محله فرمانیه" },
    ]
  },

  {
    title: 'تمرینات کاردیو چیست و چگونه انجام دهیم با عکس | تمرینات کاردیو راهی ساده برای کاهش وزن و تناسب اندام | اصول و نحوه انجام حرکات ورزش کاردیو در منزل | معرفی بهترین مربی کاردیو | هومینجا بهترین سایت سلامت و ورزش',
    h1: " تمرینات کاردیو برای کاهش وزن و چربی سوزی",
    desc: "تمرینات کاردیو شامل تمریناتی می باشد که ضربان قلب شما را افزایش می دهد و باعث بهبود عملکرد شش ها و قلب و سیستم گردش خون می شود.",
    author: "نیایش تاجی",
    date: "2023/08/22",
    image: articleImage67,
    link: "/What-Is-Cardio-Exercises",
    tags: ["دانستنی ها"],
    access: [
      { link: "/What-Is-Functional-Training", text: "فانکشنال ترینینگ" },
      { link: "/weight-loss-and-fitness", text: "کاهش اصولی وزن" },
    ]
  },
  {
    title: 'بررسی صفر تاصد محله جمشیدیه تهران همراه با راهنمای قیمت آپارتمان در جمشیدیه | هومینجا بهترین سایت خرید و فروش ملک و رهن و اجاره آپارتمان در جمشیدیه تهران',
    h1: " جمشیدیه تهران | معرفی کامل محله جمشیدیه با امکانات، عکس و نقشه",
    desc: "جمشیدیه تهران با ارتفاع متوسط ۱۸۰۰ متر بالاتر از سطح دریا، یکی از مرتفع‌ترین مناطق مسکونی تهران به‌شمار می‌آید.",
    author: "نیلوفر پورقلی",
    date: "2023/09/03",
    image: articleImage68,
    link: "/Jamshidie-Neighborhood",
    tags: ["محله گردی"],
    access: [
      { link: "/Listings/buy_tehran_jamshideh", text: "خرید ملک در جمشیدیه" },
      { link: "/Listings/buy_tehran_jamaran", text: "خرید ملک در جماران" },
      { link: "/Listings/buy_tehran_niyavaran", text: "خرید ملک در نیاوران" },
    ]
  },
  {
    title: 'رژیم فستینگ یا رژیم روزه داری چیست وچطور باعث لاغری می شود',
    h1: " رژیم فستینگ چیست؟ انواع آن و خوراکی های مجاز این رژیم",
    desc: "رژیم فستینگ یا روزه داری روشی برای کاهش وزن می باشد که در آن فرد چرخه ای از دو دوره روزه داری و غذاخوری را طی می کند.",
    author: "نیایش تاجی",
    date: "2023/09/13",
    image: articleImage69,
    link: "/Fasting-Diet",
    tags: ["دانستنی ها"],
    access: [
      { link: "/What-Is-Cardio-Exercises", text: "تمرینات کاردیو" },
      { link: "/weight-loss-and-fitness", text: "کاهش وزن اصولی" },
      { link: "/What-Is-Functional-Training", text: "فانکشنال ترینینگ" },
    ]
  },
  {
    title: 'یوگا چیست؟ فواید و معرفی حرکات این ورزش در خانه',
    h1: " ورزش یوگا چیست ؟ حرکات مفید YOGA درمنزل برای آرامش جسم و ذهن",
    desc: "به مجموعه ای از فعالیت ذهنی، روحی و جسمی برای رسیدن به آرامش و سلامت بدن در فلسفه سنتی هند باستان، یوگا می گویند.",
    author: "نیایش تاجی",
    date: "2023/10/24",
    image: articleImage70,
    link: "/What-Is-YOGA-Exercise",
    tags: ["دانستنی ها"],
    access: [
      { link: "/What-Is-Cardio-Exercises", text: "تمرینات کاردیو" },
      { link: "/Fasting-Diet", text: "رژیم فستینگ" },
      { link: "/What-Is-Functional-Training", text: "فانکشنال ترینینگ" },
    ]
  },
  {
    title: ' تحلیل وضعیت بازار ملک در سال 1403 و پیش‌بینی تحولات آن در سال 1404',
    h1: "بررسی وضعیت بازار ملک در سال 1403 و پیش بینی روند مسکن در سال 1404",
    desc: " در این مقاله به تحلیل وضعیت بازار ملک در سال 1403 و عوامل مؤثر بر قیمت‌ها و تقاضا پرداخته شده است. همچنین پیش‌بینی‌هایی برای سال 1404 ارائه می‌شود تا چشم‌اندازی از تحولات بازار مسکن ایران در آینده نزدیک به دست آید.",
    author: "هیرو برائی",
    date: "2025/03/03",
    image: articleImage71,
    link: "/Analyzing-The-Real-Estate-Market",
    tags: ["بازار مسکن"],
    access: [
      { link: "/what-is-house-renovation", text: "صفرتاصد بازسازی خانه " },
      { link: "/Home-Automation", text: "ساختمان هوشمند چیست " },
      { link: "/Andarzgoo-blvd", text: "معرفی محله اندرزگو و خیابان خوشمزه تهران" },
    ]
  },
]


